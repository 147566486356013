<template>
  <section style="height: 100%">
    <div class="flex align-center" style="padding: 15px 0">
      <img :src="bs" alt="" />
      <span
        style="
          color: #ffffff;
          font-size: 18px;
          font-weight: bold;
          margin-left: 10px;
        "
        >资金状况</span
      >
    </div>
    <div style="height: 75%; border: 1px #2ba3ff dashed">
      <div
        id="fundECharts"
        style="height: 100%; width: 99%; margin-left: 5px"
      ></div>
    </div>
  </section>
</template>

<script>
import * as echarts from 'echarts'
import { getPaymentBar } from '@/api/data-screen'
import { ParseFloatFormat } from '@custom'

const bs = require('@images/icon/bs.png')
export default {
  name: 'fund-situation',
  data () {
    return {
      bs,
      ECharts: null,

      options: {
        legend: {
            textStyle:{
			color:"#fff"
		}
        },

        tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' } },
        grid: { x: 60, y: 30, x2: 30, y2: 30 },
        xAxis: [
          {
            type: 'category',
            axisPointer: {
              type: 'shadow'
            },
            axisLabel: { textStyle: { fontSize: 10, color: '#33A6FF' } }
          }
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: {
              show: true,
              lineStyle: { type: 'dashed', color: '#2F5594' }
            },
            axisLabel: { textStyle: { fontSize: 10, color: '#33A6FF' } }
          }
        ],
        series: [
          {
            name: '应收金额',
            type: 'bar',
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#FFE330' },
                { offset: 1, color: '#FFFFFF' }
              ]),
              borderRadius: [2, 2, 0, 0]
            },
            tooltip: {
              valueFormatter: function (value) {
                return '应收金额: ' + value + ' 元'
              }
            }
          },
          {
            name: '实收金额',
            type: 'bar',
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#308AFF' }
              ]),
              borderRadius: [2, 2, 0, 0]
            },
            tooltip: {
              valueFormatter: function (value) {
                return '实收金额: ' + value + ' 元'
              }
            }
          }
        ]
      }
    }
  },
  components: {},
  methods: {
    eChartsInit (eChartsName, eChartsOptions) {
      let { ...options } = eChartsOptions
      this[eChartsName] = echarts.init(document.getElementById(eChartsName))
      this[eChartsName].setOption(options, true)
      window.addEventListener('resize', () => {
        this[eChartsName].resize()
      })
    },
    formatOption (eChartsOptions, xData, data1, data2) {
      eChartsOptions.xAxis[0].data = xData
      eChartsOptions.series[0].data = data1
      eChartsOptions.series[1].data = data2
      return eChartsOptions
    },
    getPaymentBar () {
      const that = this
      const { options } = that
      getPaymentBar().then(res => {
        const {
          info: { month, data1, data2 }
        } = res
        const receivable = data1.map(v => ParseFloatFormat(v))
        const netReceipts = data2.map(v => ParseFloatFormat(v))
        const eChartsOptions = that.formatOption(
          options,
          month,
          netReceipts,
          receivable
        )
        that.eChartsInit('fundECharts', eChartsOptions)
      })
    }
  },
  mounted () {
    this.getPaymentBar()
  }
}
</script>

<style scoped></style>
